import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
// import ProjectImage from "./ProjectImage";
// import Particle from "../Particle";

// import { Slide } from "react-reveal";

import scgBeatDnoising from "../../Assets/Projects/scg_denoising_bhi.png";
import syntehticSCGGenerator from "../../Assets/Projects/synth_scg_generator.png";
import shuntHealth from "../../Assets/Projects/choa_shunt_health.png";
import scgDenoisingWindow from "../../Assets/Projects/scg_denoising_window.png";
// import SeismoNet from "../../Assets/Projects/SeismoNet.png";
import KneeMS from "../../Assets/Projects/KneeMS.png";
import SdohNLP from "../../Assets/Projects/sdoh_nlp.png";
import GUIs from "../../Assets/Projects/GUIs.png";
// import BOLD from "../../Assets/Projects/BOLD.png";
// import ScrollAnimation from 'react-animate-on-scroll'

function Projects() {
  return (
    <Container fluid className="project-section">
      {/* <Particle /> */}
      <Container>
        <h1 className="project-heading">My Recent Projects</h1>
        {/* <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p> */}
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {/* <Slide left duration={2000}> */}
          {/* <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut"> */}
          <Col md={12} className="project-card">
            <ProjectCard
              // bacground_img={bcg1}
              imgPath={scgBeatDnoising}
              isBlog={false}
              isDemo={true}
              title="Cardio-mechanical Signal Reconstruction Without Clean Labels (U-Net)"
              description="We have developed and validated a novel SCG beat denoising model (U-Net)
               that does not rely on clean signals for training. Our findings highlight the 
               feasibility of restoring corrupted SCG beats even in the absence of corresponding 
               clean SCG beats, which are often challenging to acquire. By utilizing our proposed 
               model, it is now possible to effectively reduce noise in corrupted SCG beats while 
               preserving the essential cardiac information, leading to improved feature extraction and cardiac health parameter estimation."
              // ghLink="https://github.com/mohnikbakht/Noise2Noise_SCG_Beats_Demo"
              paperLink="https://ieeexplore.ieee.org/document/10313428"
            />
          </Col>
          {/* </Slide> */}
          {/* </ScrollAnimation> */}
          {/* <Slide right duration={2000}> */}
          <Col md={12} className="project-card">
            <ProjectCard
              // isRight={true}
              imgPath={syntehticSCGGenerator}
              isBlog={false}
              title="Synthetic Cardio-mechanical Signal Generation (Transformers)"
              description="We introduce a deep generative model, leveraging transformer neural 
              networks, specifically designed for SCG dataset augmentation. This innovative model 
              allows for fine-grained control over crucial features such as aortic opening (AO), 
              aortic closing (AC), and participant-specific morphology. By utilizing this model, 
              we can generate a wide range of physiologically diverse and realistic SCG signals 
              while maintaining precise control over the features. This groundbreaking 
              capability provides a unique opportunity for dataset augmentation, online learning, and uncertainty quantification
               for cardio-mechanical signal applications."
              // ghLink="https://github.com/mohnikbakht/"
              paperLink="https://academic.oup.com/jamia/advance-article/doi/10.1093/jamia/ocad067/7117772?utm_source=authortollfreelink&utm_campaign=jamia&utm_medium=email&guestAccessKey=762b3775-722b-47d5-91ea-bedd92f7312d"
            />
          </Col>
          {/* </Slide> */}
          {/* <Slide left duration={2000}> */}
          <Col md={12} className="project-card">
            <ProjectCard
              imgPath={SdohNLP}
              isBlog={false}
              isDemo={true}
              title="Social Determinants of Health Extraction from EHR Clinical Text (NLP-LLM)"
              description="We domain adapted the Bidirectional Encoder Representations from Transformers (BERT) model using unstructured 
              clinical notes within Electronic Health Records (EHR), and fine-tuned the model for a multilabel Social Determinants 
              of Health (SDoH) classification downstream task. By utilizing this model, we can effectively extract and accurately categorize 
               SDoH information from these clinical notes, thereby enabling the expansion of dataset 
               features and facilitating analysis of health outcomes within the OMNY Health integrated 
               healthcare network platform."
              // ghLink="https://github.com/mohnikbakht/SDoH_NLP_Demo"
              // paper_link="https://academic.oup.com/jamia/advance-article/doi/10.1093/jamia/ocad067/7117772"
            />
          </Col>
          {/* </Slide> */}
          {/* <Slide right duration={2000}> */}
          <Col md={12} className="project-card">
            <ProjectCard
              // isRight={true}
              imgPath={scgDenoisingWindow}
              isBlog={false}
              isDemo={true}
              title="A Residual U-Net Neural Network for Seismocardiogram Denoising: Improved Cardiomechanical Health Monitoring During Walking (U-Net)"
              description="We developed a deep denoising model (U-Net) specifically tailored 
              to mitigate the impact of motion noise in SCG signals during walking. The proposed 
              model has significant applications in enhancing the quality of SCG signals recorded 
              using wearable devices in clinical settings or at home. This novel model significantly 
              enhances the accuracy and reliability of estimating crucial parameters, such as Pre-ejection Period (PEP), 
              Left Ventricular Ejection Time (LVET), and Heart Rate (HR), during walking activities. This advancement holds 
              great promise in improving the overall quality and utility of SCG signals for both clinical and sports-related applications."
              // ghLink="https://github.com/mohnikbakht/SCG_Walking_Denoising"
              // paper_link="https://academic.oup.com/jamia/advance-article/doi/10.1093/jamia/ocad067/7117772"
            />
          </Col>
          {/* </Slide> */}
          {/* <Slide left duration={2000}> */}
          <Col md={12} className="project-card">
            <ProjectCard
              imgPath={shuntHealth}
              isBlog={false}
              isDemo={true}
              title="Application of Acoustic Signals in Systemic to Pulmonary Shunts using Deep Learning"
              description="Our preliminary data revealed, for the first time, that in fact “whooshing” 
              of a systolic ejection murmur through a systemic to pulmonary connection is distinct 
              based on the shunt type. In this work we developed supervised (CNN-LSTM) and unsupervised (VAE)
               deep learning models to classify the auditory characteristics of blood flow through shunts. This groundbreaking 
               technology can enable frequent evaluations using sounds recorded with a digital stethoscope, ensuring the sustained
                functionality and prolonged lifespan of the shunts."
              // ghLink="https://github.com/mohnikbakht/PCG_Shunt_Demo"
              // paper_link="https://academic.oup.com/jamia/advance-article/doi/10.1093/jamia/ocad067/7117772"
            />
          </Col>
          {/* </Slide> */}
          {/* <Slide right duration={2000}> */}
          {/* <Col md={12} className="project-card">
            <ProjectCard
              // isRight={true}
              imgPath={BOLD}
              isBlog={false}
              isDemo={true}
              title="Deep Learning Express: An Accessible and Comprehensive Educational Resource for Deep Learning Beginners"
              description="developed a comprehensive and categorized resource specifically 
              designed for new learners interested in deep learning. This resource provides
               an ideal starting point for individuals looking to delve into this field. 
               It consists of six Google Colab notebooks, each offering hands-on examples and
                practical exercises."
              ghLink="https://mohnikbakht.github.io/deeplearningexpress.github.io/"

              // paper_link="https://academic.oup.com/jamia/advance-article/doi/10.1093/jamia/ocad067/7117772"
            />
          </Col>
          {/* </Slide> */}
          {/* <Slide left duration={2000}> */}
          {/* <Col md={12} className="project-card">
            <ProjectCard
              imgPath={SeismoNet}
              isBlog={false}
              isDemo={true}
              title="SeismoNet: A Multi-Node Wireless Wearable Platform"
              description="Introducing SeismoNet, an innovative wireless wearable platform designed to 
              be highly sensitive and low-power. This platform revolutionizes data collection by 
              enabling the recording of small amplitude acceleration signals, specifically focused on
               capturing Seismocardiogram (SCG) signals, as well as Electrocardiogram (ECG) signals, 
               from multiple points on the human body."
              ghLink="https://github.com/mohnikbakht/SeismoNet_Wearable_Demo"
              // paper_link="https://academic.oup.com/jamia/advance-article/doi/10.1093/jamia/ocad067/7117772"
            />
          </Col> */}
          {/* </Slide> */}
          {/* <Slide right duration={2000}> */}
          <Col md={12} className="project-card">
            <ProjectCard
              // isRight={true}
              imgPath={GUIs}
              isBlog={false}
              isDemo={true}
              title="A Multi-Node Wearable Platform (SeismoNet) with Graphical User Interfaces"
              description="We developed SeismoNet, a modular multi-node wireless wearable platform capable 
              of recording physiological signals from multiple points of the human body synchronously, 
              with three intuitive and user-friendly Graphical User Interfaces (GUIs).
                 (a) Google Glass user interface designed for seamless integration with Google Glass, 
                offering a hands-free and immersive user experience.
                 (b) Laptop GUI provides a comprehensive and feature-rich interface for controlling the 
                SeismoNet nodes and visualizing the signals.
                 (c) Smartphone GUI offers the convenience of controlling the SeismoNet nodes and 
                 monitoring the signals using a mobile device."
              // ghLink="https://github.com/mohnikbakht/SeismoNet_Wearable_Demo"

              paperLink="https://ieeexplore.ieee.org/document/10331306"
            />
          </Col>
          {/* </Slide> */}
          {/* <Slide left duration={2000}> */}
          <Col md={12} className="project-card">
            <ProjectCard
              imgPath={KneeMS}
              isBlog={false}
              isDemo={true}
              title="KneeMS: A Low-Cost Wireless Wearable System to Monitor Knee Acoustic Emissions"
              description="The work centers on wearable joint health monitoring through acoustical sensing. 
                It presents a miniaturized, fully digital sensing system designed for convenient attachment to 
                the knee using adhesive. The system offers potential applications in streamlined data collection for 
                monitoring athletes and individuals with joint disorders, as well as future prospects for decentralized 
                digital clinical trials.
                Best Paper Award winner at the 2023 IEEE EMBS International Conference on Body Sensor Networks: Sensor and Systems for Digital Health (IEEE BSN 2023)"
              // ghLink="https://github.com/mohnikbakht/KneeMS_Demo"
              paperLink="https://ieeexplore.ieee.org/document/10330997"
            />
          </Col>
          {/* </Slide> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
