import React from "react";
import { Col, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";

// import { CgCPlusPlus } from "react-icons/cg";
import {
  // DiJavascript1,
  // DiReact,
  // DiNodejs,
  // DiMongodb,
  // DiPython,
  // DiGit,
  // DiJava,
  DiSpark,
} from "react-icons/di";
import {
  // SiRedis,
  // SiFirebase,
  // SiNextdotjs,
  // SiSolidity,
  // SiPostgresql,
  // SiC,
  // SiFlask,
  // SiPython,
  SiPytorch,
  SiTensorflow,
  SiScikitlearn,
  SiScipy,
  SiPandas,
  // SiGooglecolab,
  // SiGooglecloud,
  SiNumpy,
} from "react-icons/si";
// import { TbBrandCSharp, TbLetterC } from "react-icons/tb";

// import huggingface from "../../Assets/huggingface.png";

function MLtools() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiPytorch />
        <p className="tech-icons-text">PyTorch</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTensorflow />
        <p className="tech-icons-text">TensorFlow</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        {/* <img
          src={huggingface}
          alt="Logo"
          style={{ maxWidth: "80%", height: "auto" }}
        /> */}
        <Icon icon="fluent-emoji-high-contrast:hugging-face" />
        <p className="tech-icons-text">Hugging Face</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiSpark />
        <p className="tech-icons-text">Spark</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiScikitlearn />
        <p className="tech-icons-text">ScikitLearn</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiScipy />
        <p className="tech-icons-text">Scipy</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPandas />
        <p className="tech-icons-text">Pandas</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiNumpy />
        <p className="tech-icons-text">Numpy</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <Icon icon="simple-icons:opencv" />{" "}
        <p className="tech-icons-text">OpenCV</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <Icon icon="simple-icons:weightsandbiases" />
        <p className="tech-icons-text">WandB</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <Icon icon="simple-icons:onnx" />
        <p className="tech-icons-text">ONNX</p>
      </Col>
    </Row>
  );
}

export default MLtools;
