import React from "react";
import { Container } from "react-bootstrap";
// import Particle from "../Particle";
import ListGroup from "react-bootstrap/ListGroup";

function Publications() {
  return (
    <Container fluid className="publication-section">
      {/* <Particle /> */}
      <Container>
        <h1 className="project-heading">My Publications</h1>
        <br />
        <ListGroup as="ol" numbered className="publication-list">
          <ListGroup.Item as="li" className="publication-list-item">
            Nikbakht, M., Gazi, A.H., Zia, J., An, S., Lin, D.J., Inan, O.T. and
            Kamaleswaran, R., 2023. Synthetic seismocardiogram generation using
            a transformer-based neural network. Journal of the American Medical
            Informatics Association, p.ocad067.
          </ListGroup.Item>
          <ListGroup.Item as="li" className="publication-list-item">
            Nikbakht, M., Sanchez-Perez, J. A., Aljiffry, A., Maher, K., Inan,
            O. T., & Rodriguez, S., Application of Acoustic Signals in Systemic
            to Pulmonary Shunts in Ductal Dependent Infants using Deep Learning.
            Under review at IEEE JBHI
          </ListGroup.Item>

          <ListGroup.Item as="li" className="publication-list-item">
            Nikbakht, Lin, D.J., and Inan, O.T.. Learning Seismocardiogram Beat
            Denoising Without Clean Data. Accepted at BHI conference 2023.
          </ListGroup.Item>

          <ListGroup.Item as="li" className="publication-list-item">
            Nikbakht, M., Chan, M., Lin, D.J., Gazi, A.H., and Inan, O.T.. A
            U-Net-Based Approach for Seismocardiogram Denoising: Improved
            Cardiomechanical Health Monitoring During Walking. under review at
            JAMIA.
          </ListGroup.Item>

          <ListGroup.Item as="li" className="publication-list-item">
            Nikbakht, M., Kumar, V., Gazi, A. H., & Rasouliyan, L., Extracting
            Social Determinants of Health from Unstructured Clinical Notes Using
            Transformer Based Natural Language Processing Models, under review
            at JMIR.
          </ListGroup.Item>

          <ListGroup.Item as="li" className="publication-list-item">
            Nikbakht, M., Lin, D.J., Gazi, A.H. and Inan, O.T., 2022, October. A
            Synthetic Seismocardiogram and Electrocardiogram Generator Phantom.
            In 2022 IEEE Sensors (pp. 1-4). IEEE.
          </ListGroup.Item>

          <ListGroup.Item as="li" className="publication-list-item">
            Nikbakht, M., Chan, M., Lin, D.J., Nicholson, C.J., Bibidakis, M.,
            Soliman, M., and Inan, O.T.. SeismoNet: A Multi-Node Wireless
            Wearable Platform for Enhanced Physiological Sensing. Accepted at
            BSN conference 2023.
          </ListGroup.Item>

          <ListGroup.Item as="li" className="publication-list-item">
            Nikbakht, M., Goossens, Q., Ozmen, G.C., Bibidakis, M., Lin, D.J.,
            and Inan, O.T.. KneeMS: A Low-Cost Wireless Wearable System to
            Monitor Knee Acoustic Emissions. Accepted at BSN conference 2023.
          </ListGroup.Item>

          <ListGroup.Item as="li" className="publication-list-item">
            Nikbakht, M., Pakbin, B. and Nikbakht Brujeni, G., 2019. Evaluation
            of a new lymphocyte proliferation assay based on cyclic voltammetry;
            an alternative method. Scientific Reports, 9(1), p.4503.
          </ListGroup.Item>

          <ListGroup.Item as="li" className="publication-list-item">
            Chan, M., Gazi, A.H., Soliman, M., Richardson, K.L., Abdallah, C.A.,
            Ozmen, G.C., Nikbakht, M. and Inan, O.T., 2022, October. Estimating
            Heart Rate from Seismocardiogram Signal using a Novel Deep Dominant
            Frequency Regressor and Domain Adversarial Training. In 2022 IEEE
            Biomedical Circuits and Systems Conference (BioCAS) (pp. 158-162).
            IEEE.
          </ListGroup.Item>
          <ListGroup.Item as="li" className="publication-list-item">
            Lin, D.J., Gazi, A.H., Kimball, J., Nikbakht, M. and Inan, O.T.,
            2023. Real-Time Seismocardiogram Feature Extraction Using Adaptive
            Gaussian Mixture Models. IEEE Journal of Biomedical and Health
            Informatics.
          </ListGroup.Item>

          <ListGroup.Item as="li" className="publication-list-item">
            Bhattacharya, S., Nikbakht, M., Alden, A., Tan, P., Wang, J.,
            Alhalimi, T.A., Kim, S., Wang, P., Tanaka, H., Tandon, A. and Coyle,
            E.F., 2023. A Chest‐Conformable, Wireless Electro‐Mechanical
            E‐Tattoo for Measuring Multiple Cardiac Time Intervals. Advanced
            Electronic Materials, p.2201284.
          </ListGroup.Item>
          <ListGroup.Item as="li" className="publication-list-item">
            Bhattacharya, S., Nikbakht, M., Alden, A., Ambani, K., Tan, P.,
            Alhalimi, T.A., Wang, J., Tanaka, H., Coyle, E.F., Inan, O. and Lu,
            N., 2022, November. Dual-Mode Chest Wearable E-Tattoo for the Mobile
            Detection of Cardiac Time Intervals. In 2022 IEEE/ACM Conference on
            Connected Health: Applications, Systems and Engineering Technologies
            (CHASE) (pp. 123-127). IEEE.
          </ListGroup.Item>
        </ListGroup>
      </Container>
    </Container>
  );
}

export default Publications;
