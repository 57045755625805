import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Particle from "../Particle";
// import Github from "./Github";
// import Techstack from "./Techstack";
// import Aboutcard from "./AboutCard";
import aboutImg from "../../Assets/thisisfine.png";
// import hobbiesImg from "../../Assets/hobbies.png";
// import backgroundAbout from "../../Assets/profile_pic.png";
// import Toolstack from "./Toolstack";
// import MLtools from "./MLtools";
function AboutMe() {
  return (
    <Container fluid className="about-section">
      <Row>
        <Col
          xs={12}
          md={6}
          style={{
            display: "flex",
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
          <p className="home-about-body">
            I am a graduating PhD student at Georgia Tech, specializing in
            health AI. With a track record of research and projects in this
            field, I am passionate about leveraging cutting-edge technology to
            drive transformative advancements in healthcare. My goal is to
            facilitate more efficient and personalized care to benefit
            individuals worldwide. Whether you're interested in collaboration,
            job opportunities, or simply want to connect, I invite you to
            explore my portfolio and get in touch.
            {/* I am a PhD student at Georgia Tech with a passion for using
            cutting-edge technology to advance healthcare. My main expertise are
            developing deep learning architectures for tasks such as
            <i>
              <b className="purple"> sequence modeling</b>
            </i>
            &nbsp;,
            <i>
              <b className="purple"> syntehtic data generation</b>
            </i>
            &nbsp;,{" "}
            <i>
              <b className="purple"> signal\image reconstruction</b>
            </i>
            &nbsp; and
            <i>
              {" "}
              <b className="purple"> patient condition classification</b>
            </i> */}
            {/* <br /> */}
            {/* <br /> */}
            {/* <em>** This website is coded with React JS **</em> */}
          </p>
        </Col>
        <Col xs={12} md={6}>
          <img src={aboutImg} className="home-about-image" alt="home" />
        </Col>
      </Row>

      {/* <Row>
        <Col
          xs={12}
          md={6}
          style={{
            display: "flex",
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
          <p className="home-about-body">
            When I'm not coding 😁 I cook and bake 👨‍🍳, play tennis 🎾 and
            basketball 🏀, or I'm outdoors hiking 🏞️ and grilling 🔥
          </p>
        </Col>
        <Col xs={12} md={6}>
          <img src={hobbiesImg} className="home-about-image" />
        </Col>
      </Row> */}
    </Container>
  );
}

export default AboutMe;
