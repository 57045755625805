import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Tilt from "react-parallax-tilt";
// import Particle from "../Particle";
import georgiaTech from "../../Assets/Georgia_Tech_seal.png";
// import Tiger from "../../Assets/tiger.png";
import uniTehran from "../../Assets/uniTehran.png";
import { Fade } from "react-reveal";

function Education() {
  return (
    <Container fluid className="education-section">
      {/* <Particle /> */}
      <Container>
        <Row>
          <Col>
            <h1 className="education-heading">My Education</h1>
          </Col>
        </Row>
        {/* <Fade duration={3000}> */}
        <Row className="education-row">
          <Col xs={12} md={2} className="education-row-logo">
            <img src={georgiaTech} className="img-fluid" alt="GT" />
          </Col>
          <Col xs={12} md={4} className="education-row-date">
            <p>Aug 2019 - Present</p>
          </Col>
          <Col xs={12} md={6} className="education-row-text">
            <p>
              Pursuing a PhD in Electrical and Computer Engineering at the
              Georgia Institute of Technology, School of Electrical and Computer
              Engineering, Atlanta, Georgia
            </p>
          </Col>
        </Row>
        {/* </Fade> */}
        {/* <Fade duration={3000}> */}
        <Row className="education-row">
          <Col xs={12} md={2} className="education-row-logo">
            <img src={georgiaTech} className="img-fluid" alt="Tiger" />
          </Col>
          <Col xs={12} md={4} className="education-row-date">
            <p>Aug 2021 - May 2022</p>
          </Col>
          <Col xs={12} md={6} className="education-row-text">
            <p>
              Successfully completed the TI:GER Program, Technology Innovation:
              Generating Economic Results and awarded the certificate by the
              Georgia Institute of Technology, Scheller College of Business
              Atlanta, Georgia
            </p>
          </Col>
        </Row>
        {/* </Fade> */}
        {/* <Fade duration={3000}> */}
        <Row className="education-row">
          <Col xs={12} md={2} className="education-row-logo">
            <img src={georgiaTech} className="img-fluid" alt="GT" />
          </Col>
          <Col xs={12} md={4} className="education-row-date">
            <p>Aug 2019 - May 2021</p>
          </Col>
          <Col xs={12} md={6} className="education-row-text">
            <p>
              Earned an MSc degree from the Georgia Institute of Technology,
              School of Electrical and Computer Engineering, Atlanta, Georgia
            </p>
          </Col>
        </Row>
        {/* </Fade> */}
        {/* <Fade duration={3000}> */}
        <Row className="education-row">
          <Col xs={12} md={2} className="education-row-logo">
            <img src={uniTehran} className="img-fluid" alt="UT" />
          </Col>
          <Col xs={12} md={4} className="education-row-date">
            <p>Aug 2015 - May 2019</p>
          </Col>
          <Col xs={12} md={6} className="education-row-text">
            <p>
              Earned an BSc degree from the University of Tehran, School of
              Electrical and Computer Engineering, Tehran
            </p>
          </Col>
        </Row>
        {/* </Fade> */}
      </Container>
    </Container>
  );
}
export default Education;
