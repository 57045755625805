import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Particle from "../Particle";
// import Github from "./Github";
// import Techstack from "./Techstack";
// import Aboutcard from "./AboutCard";
// import aboutImg from "../../Assets/thisisfine.png";
import hobbiesImg from "../../Assets/hobbies.png";
// import backgroundAbout from "../../Assets/profile_pic.png";
// import Toolstack from "./Toolstack";
// import MLtools from "./MLtools";
function AboutMeEnd() {
  return (
    <Container fluid className="about-section-end">
      <Row>
        <h1> A little more about me </h1>

        <Col
          xs={12}
          md={6}
          style={{
            display: "flex",
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
          <p className="home-about-body">
            When I'm not coding (or the models are training) 😁 I cook and bake
            👨‍🍳, play tennis 🎾 and basketball 🏀, or I'm outdoors hiking 🏞️ and
            grilling 🔥
          </p>
        </Col>
        <Col xs={12} md={6}>
          <img src={hobbiesImg} className="home-about-image" />
        </Col>
      </Row>
    </Container>
  );
}

export default AboutMeEnd;
