import React from "react";
import { Col, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
// import { CgCPlusPlus } from "react-icons/cg";
import {
  // DiJavascript1,
  DiReact,
  // DiNodejs,
  // DiMongodb,
  // DiPython,
  // DiGit,
  // DiJava,
  // DiSpark,
} from "react-icons/di";
import {
  // SiRedis,
  // SiFirebase,
  // SiNextdotjs,
  // SiSolidity,
  // SiPostgresql,
  // SiC,
  // SiFlask,
  SiPython,
  // SiPytorch,
  // SiTensorflow,
  // SiScikitlearn,
  // SiScipy,
  // SiPandas,
  // SiGooglecolab,
  // SiGooglecloud,
} from "react-icons/si";
import {
  // TbBrandGolang,
  TbBrandCSharp,
  TbLetterC,
  TbBrandReactNative,
} from "react-icons/tb";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiPython />
        <p className="tech-icons-text">Python</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <Icon icon="file-icons:matlab" />
        <p className="tech-icons-text">MATLAB</p>
      </Col>

      {/* <Col xs={4} md={2} className="tech-icons">
        <SiFlask />
        <p className="tech-icons-text">Flask</p>
      </Col> */}
      <Col xs={4} md={2} className="tech-icons">
        <TbLetterC />
        <p className="tech-icons-text">C</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <TbBrandCSharp />
        <p className="tech-icons-text">C-Sharp</p>
      </Col>
      {/* <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <p className="tech-icons-text">Javascript</p>
      </Col> */}
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <p className="tech-icons-text">React JS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <TbBrandReactNative />
        <p className="tech-icons-text">React Native</p>
      </Col>
    </Row>
  );
}

export default Techstack;
