import React from "react";
import { Col, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { DiGit } from "react-icons/di";
import {
  SiVisualstudiocode,
  // SiPostman,
  // SiSlack,
  // SiVercel,
  // SiMacos,
  SiGooglecloud,
  SiGooglecolab,
  SiLinux,
  SiJupyter,
  SiAmazonaws,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiJupyter />
        <p className="tech-icons-text">Jupyter</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
        <p className="tech-icons-text">VS Code</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiGooglecloud />
        <p className="tech-icons-text">Google Cloud</p>
      </Col>
      {/* <Col xs={4} md={2} className="tech-icons">
        <SiAmazonaws />
        <p className="tech-icons-text">AWS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGooglecolab />
        <p className="tech-icons-text">Google Colab</p>
      </Col> */}
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux />
        <p className="tech-icons-text">Linux</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <p className="tech-icons-text">Git</p>
      </Col>
    </Row>
  );
}

export default Toolstack;
