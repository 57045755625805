import React from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import homeLogo from "../../Assets/home-main.svg";
// import Particle from "../Particle";
import Home2 from "./Home2";
// import Type from "./Type";
import Skills from "../About/About";
import Education from "../Education/Education";
import Projects from "../Projects/Projects";
import Publications from "../Publications/Publications";
import About from "../About/AboutMe";
import AboutEnd from "../About/AboutMeEnd";

import { Reveal, Fade } from "react-reveal";

import { Element } from "react-scroll";

function Home() {
  return (
    <section>
      {/* <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
                Welcome to My Portfolio!
              </h1>

              <h1 className="heading-name">
                I'M
                <strong className="main-name"> Mohammad Nikbakht </strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container> */}

      <Element id="home-scroll" name="home-scroll">
        <Home2 />
      </Element>
      <Element id="about-scroll" name="about-scroll">
        <About />
      </Element>

      <Reveal effect="fadeInUp" fraction="0.05">
        <Element id="skills-scroll" name="skills-scroll">
          <Skills />
        </Element>
      </Reveal>

      <Element id="projects-scroll" name="projects-scroll">
        <Projects />
      </Element>

      {/* <Reveal effect="fadeInUp" fraction="0.05"> */}
      <Element id="education-scroll" name="education-scroll">
        <Education />
      </Element>
      {/* </Reveal> */}

      <Element id="publication-scroll" name="publication-scroll">
        <Publications />
      </Element>

      <Fade duration="3000" fraction="0.05">
        <Element id="about-scroll" name="about-scroll">
          <AboutEnd />
        </Element>
      </Fade>
    </section>
  );
}

export default Home;
