import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/profile_pic.png";
// import Tilt from "react-parallax-tilt";
// import {
//   // AiFillGithub,
//   // AiOutlineTwitter,
//   // AiFillInstagram,
// } from "react-icons/ai";
// import { FaLinkedinIn } from "react-icons/fa";
import Type from "./Type";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col xs={12} md={8} className="home-about-description">
            {/* <h1 style={{ fontSize: "2.6em" }}>
              Bio
            </h1> */}
            <h1 style={{ paddingBottom: 15 }} className="heading">
              Hello!{" "}
              {/* <span className="wave" role="img" aria-labelledby="wave">
                👋🏻
              </span>{" "} */}
              Welcome to My Portfolio!
            </h1>

            <h1 className="heading-name">
              I'm
              <strong className="main-name"> Mohammad Nikbakht </strong>
            </h1>
            <div style={{ textAlign: "center" }}>
              {/* <p className="main-name">data scientist / software developer</p> */}
              <Type />
            </div>
            {/* <p className="home-about-body">
              I am a PhD student at Georgia Tech with a passion for using
              cutting-edge technology to advance healthcare. My main expertise
              are developing deep learning architectures for tasks such as
              <i>
                <b className="purple"> sequence modeling</b>
              </i>
              &nbsp;,
              <i>
                <b className="purple"> syntehtic data generation</b>
              </i>
              &nbsp;,{" "}
              <i>
                <b className="purple"> signal\image reconstruction</b>
              </i>
              &nbsp; and
              <i>
                {" "}
                <b className="purple"> patient condition classification</b>
              </i>
              .
              <br />
              <br /> */}
            {/* <br />
              My field of Interest's are building new &nbsp;
              <i>
                <b className="purple">Web Technologies and Products </b> 
                </i>
              &nbsp; like
              <i>
                also in areas related to{" "}
                <b className="purple">
                  Blockchain.
                </b>
              </i>
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js and Next.js</b>
              </i>*/}
            {/* </p> */}
          </Col>
          <Col xs={12} md={4} className="home-about-photo">
            {/* <Tilt> */}
            <img src={myImg} className="img-fluid" />
            {/* </Tilt> */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
