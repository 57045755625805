import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { IoIosPaper } from "react-icons/io";
function ProjectCards(props) {
  return (
    <Container style={{ backgroundImage: `url(${props.bacground_img})` }}>
      <Row>
        {/* {!props.isRight && (
          <Col xs={12} style={{ display: "flex", alignSelf: "center" }}>
            <Figure>
              <Figure.Image
                alt="card-img"
                className="project-image-view"
                src={props.imgPath}
              />
            </Figure>
          </Col>
        )} */}
        <Col xs={12} style={{ display: "flex", alignSelf: "center" }}>
          <Card className="project-card-view">
            <Card.Img
              variant="left"
              src={props.imgPath}
              alt="card-img"
              style={{ width: "60%", alignSelf: "center" }}
            />
            <Card.Body>
              <Card.Title style={{ fontWeight: "bold", fontSize: "1.5vw" }}>
                {props.title}
              </Card.Title>
              <Card.Text style={{ textAlign: "justify", fontSize: "1.2vw" }}>
                {props.description}
              </Card.Text>
              {props.ghLink && (
                <Button
                  variant="primary"
                  href={props.ghLink}
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    fontSize: "1.5vw",
                    paddingLeft: "3px",
                    paddingRight: "3px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                  }}
                >
                  <BsGithub /> &nbsp;
                  {props.isDemo ? "Demo" : "GitHub"}
                </Button>
              )}
              {"\n"}
              {"\n"}

              {props.paperLink && (
                <Button
                  variant="primary"
                  href={props.paperLink}
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    fontSize: "1.5vw",
                    paddingLeft: "3px",
                    paddingRight: "3px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                  }}
                >
                  <IoIosPaper /> &nbsp;
                  {"Paper"}
                </Button>
              )}
              {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

              {!props.isBlog && props.demoLink && (
                <Button
                  variant="primary"
                  href={props.demoLink}
                  target="_blank"
                  style={{
                    marginLeft: "10px",
                    fontSize: "1.5vw",
                    paddingLeft: "3px",
                    paddingRight: "3px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                  }}
                >
                  <CgWebsite /> &nbsp;
                  {"Demo"}
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
        {/* {props.isRight && (
          <Col xs={12} style={{ display: "flex", alignSelf: "center" }}>
            <Figure>
              <Figure.Image
                className="project-image-view"
                src={props.imgPath}
              />
            </Figure>
          </Col>
        )} */}
      </Row>
    </Container>
  );
}
export default ProjectCards;
